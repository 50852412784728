export const logs = [
  // {
  //     title: "offer.janowo.diary.timeline.february",
  //     year: 2024,
  //     pics: [
  //         [
  //         ],
  //         [
  //         ],
  //     ],
  //     alts: [],
  // },
  {
    title: "offer.janowo.diary.timeline.july",
    year: 2024,
    pics: [
      [
        "/assets/images/puck/diary/2024-07/20240708_162554.jpg",
        "/assets/images/puck/diary/2024-07/20240708_162606.jpg",
        "/assets/images/puck/diary/2024-07/20240723_073522.jpg",
        "/assets/images/puck/diary/2024-07/20240726_103357.jpg",
        "/assets/images/puck/diary/2024-07/20240731_184840.jpg",
        "/assets/images/puck/diary/2024-07/20240802_105906.jpg",
        "/assets/images/puck/diary/2024-07/IMG_20240715_145634.jpg",
      ],
      [
        "/assets/images/puck/diary/2024-07/mini_20240708_162554.jpg",
        "/assets/images/puck/diary/2024-07/mini_20240708_162606.jpg",
        "/assets/images/puck/diary/2024-07/mini_20240723_073522.jpg",
        "/assets/images/puck/diary/2024-07/mini_20240726_103357.jpg",
        "/assets/images/puck/diary/2024-07/mini_20240731_184840.jpg",
        "/assets/images/puck/diary/2024-07/mini_20240802_105906.jpg",
        "/assets/images/puck/diary/2024-07/mini_IMG_20240715_145634.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.june",
    year: 2024,
    pics: [
      [
        "/assets/images/puck/diary/2024-06/20240612_080131.jpg",
        "/assets/images/puck/diary/2024-06/20240613_112548.jpg",
        "/assets/images/puck/diary/2024-06/20240613_112608.jpg",
        "/assets/images/puck/diary/2024-06/20240618_122452.jpg",
        "/assets/images/puck/diary/2024-06/20240622_100235.jpg",
        "/assets/images/puck/diary/2024-06/20240626_132058.jpg",
        "/assets/images/puck/diary/2024-06/20240628_105313.jpg",
        "/assets/images/puck/diary/2024-06/20240628_105621.jpg",
      ],
      [
        "/assets/images/puck/diary/2024-06/mini_20240612_080131.jpg",
        "/assets/images/puck/diary/2024-06/mini_20240613_112548.jpg",
        "/assets/images/puck/diary/2024-06/mini_20240613_112608.jpg",
        "/assets/images/puck/diary/2024-06/mini_20240618_122452.jpg",
        "/assets/images/puck/diary/2024-06/mini_20240622_100235.jpg",
        "/assets/images/puck/diary/2024-06/mini_20240626_132058.jpg",
        "/assets/images/puck/diary/2024-06/mini_20240628_105313.jpg",
        "/assets/images/puck/diary/2024-06/mini_20240628_105621.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.may",
    year: 2024,
    pics: [
      [
        "/assets/images/puck/diary/2024-05/IMG_20240502_071530.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240502_072036.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240506_070941.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240506_071155.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240513_072542.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240514_074700.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240518_071146.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240528_070305.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240531_071453.jpg",
        "/assets/images/puck/diary/2024-05/IMG_20240582_071257.jpg",
      ],
      [
        "/assets/images/puck/diary/2024-05/mini_IMG_20240502_071530.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240502_072036.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240506_070941.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240506_071155.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240513_072542.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240514_074700.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240518_071146.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240528_070305.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240531_071453.jpg",
        "/assets/images/puck/diary/2024-05/mini_IMG_20240582_071257.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.april",
    year: 2024,
    pics: [
      [
        "/assets/images/puck/diary/2024-04/IMG_20240405_092536.jpg",
        "/assets/images/puck/diary/2024-04/IMG_20240405_093556.jpg",
        "/assets/images/puck/diary/2024-04/IMG_20240408_073127.jpg",
        "/assets/images/puck/diary/2024-04/IMG_20240409_144232.jpg",
        "/assets/images/puck/diary/2024-04/IMG_20240409_144625.jpg",
        "/assets/images/puck/diary/2024-04/IMG_20240411_065850.jpg",
        "/assets/images/puck/diary/2024-04/IMG_20240423_083021.jpg",
        "/assets/images/puck/diary/2024-04/IMG_20240426_070454.jpg",
      ],
      [
        "/assets/images/puck/diary/2024-04/mini_IMG_20240405_092536.jpg",
        "/assets/images/puck/diary/2024-04/mini_IMG_20240405_093556.jpg",
        "/assets/images/puck/diary/2024-04/mini_IMG_20240408_073127.jpg",
        "/assets/images/puck/diary/2024-04/mini_IMG_20240409_144232.jpg",
        "/assets/images/puck/diary/2024-04/mini_IMG_20240409_144625.jpg",
        "/assets/images/puck/diary/2024-04/mini_IMG_20240411_065850.jpg",
        "/assets/images/puck/diary/2024-04/mini_IMG_20240423_083021.jpg",
        "/assets/images/puck/diary/2024-04/mini_IMG_20240426_070454.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.march",
    year: 2024,
    pics: [
      [
        "/assets/images/puck/diary/2024-03/IMG_20240301_075352.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240313_145618.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240314_092613.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240315_073401.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240315_073700.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240315_073756.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240319_081404.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240319_110006.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240321_073141.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240321_110111.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240322_081129.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240327_092311.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240327_092320.jpg",
        "/assets/images/puck/diary/2024-03/IMG_20240329_092158.jpg",
      ],
      [
        "/assets/images/puck/diary/2024-03/mini_IMG_20240301_075352.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240313_145618.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240314_092613.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240315_073401.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240315_073700.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240315_073756.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240319_081404.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240319_110006.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240321_073141.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240321_110111.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240322_081129.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240327_092311.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240327_092320.jpg",
        "/assets/images/puck/diary/2024-03/mini_IMG_20240329_092158.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.february",
    year: 2024,
    pics: [
      [
        "/assets/images/puck/diary/2024-02/IMG_20240201_104444.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240202_145648.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240205_134811.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240205_134921.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240223_074454.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240223_074511.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240223_074514.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240223_102240.jpg",
        "/assets/images/puck/diary/2024-02/IMG_20240223_102242.jpg",
      ],
      [
        "/assets/images/puck/diary/2024-02/mini_IMG_20240201_104444.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240202_145648.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240205_134811.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240205_134921.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240223_074454.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240223_074511.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240223_074514.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240223_102240.jpg",
        "/assets/images/puck/diary/2024-02/mini_IMG_20240223_102242.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.january",
    year: 2024,
    pics: [
      [
        "/assets/images/puck/diary/2024-01/IMG_20240110_153118.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240110_153239.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240110_153520.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240111_131335.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240111_131339.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240112_093735.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240112_093903.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240112_094651.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240112_095443.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240116_135951.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240119_142738.jpg",
        "/assets/images/puck/diary/2024-01/IMG_20240123_131042.jpg",
      ],
      [
        "/assets/images/puck/diary/2024-01/mini_IMG_20240110_153118.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240110_153239.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240110_153520.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240111_131335.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240111_131339.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240112_093735.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240112_093903.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240112_094651.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240112_095443.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240116_135951.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240119_142738.jpg",
        "/assets/images/puck/diary/2024-01/mini_IMG_20240123_131042.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.december",
    year: 2023,
    pics: [
      [
        "/assets/images/puck/diary/2023-12/20240104_121238.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121243.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121249.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121424.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121428.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121435.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121441.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121739.jpg",
        "/assets/images/puck/diary/2023-12/20240104_121742.jpg",
        "/assets/images/puck/diary/2023-12/20240104_122105.jpg",
        "/assets/images/puck/diary/2023-12/20240104_122145.jpg",
      ],
      [
        "/assets/images/puck/diary/2023-12/mini_20240104_121238.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121243.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121249.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121424.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121428.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121435.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121441.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121739.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_121742.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_122105.jpg",
        "/assets/images/puck/diary/2023-12/mini_20240104_122145.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.november",
    year: 2023,
    pics: [
      [
        "/assets/images/puck/diary/2023-11/20231129_085837.jpg",
        "/assets/images/puck/diary/2023-11/20231129_085842.jpg",
        "/assets/images/puck/diary/2023-11/20231129_085843.jpg",
        "/assets/images/puck/diary/2023-11/20231129_085858.jpg",
        "/assets/images/puck/diary/2023-11/20231129_085900.jpg",
        "/assets/images/puck/diary/2023-11/20231129_085902.jpg",
        "/assets/images/puck/diary/2023-11/IMG_20231130_092125.jpg",
        "/assets/images/puck/diary/2023-11/IMG_20231130_092132.jpg",
        "/assets/images/puck/diary/2023-11/IMG_20231130_092319.jpg",
        "/assets/images/puck/diary/2023-11/IMG_20231130_092347.jpg",
      ],
      [
        "/assets/images/puck/diary/2023-11/mini_20231129_085837.jpg",
        "/assets/images/puck/diary/2023-11/mini_20231129_085842.jpg",
        "/assets/images/puck/diary/2023-11/mini_20231129_085843.jpg",
        "/assets/images/puck/diary/2023-11/mini_20231129_085858.jpg",
        "/assets/images/puck/diary/2023-11/mini_20231129_085900.jpg",
        "/assets/images/puck/diary/2023-11/mini_20231129_085902.jpg",
        "/assets/images/puck/diary/2023-11/mini_IMG_20231130_092125.jpg",
        "/assets/images/puck/diary/2023-11/mini_IMG_20231130_092132.jpg",
        "/assets/images/puck/diary/2023-11/mini_IMG_20231130_092319.jpg",
        "/assets/images/puck/diary/2023-11/mini_IMG_20231130_092347.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.october",
    year: 2023,
    pics: [
      [
        "/assets/images/puck/diary/2023-10/20231010_071238.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071339.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071447.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071449.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071453.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071454.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071504.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071506.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071509.jpg",
        "/assets/images/puck/diary/2023-10/20231010_071511.jpg",
        "/assets/images/puck/diary/2023-10/20231024_075553.jpg",
        "/assets/images/puck/diary/2023-10/20231024_075554.jpg",
        "/assets/images/puck/diary/2023-10/20231024_075557.jpg",
        "/assets/images/puck/diary/2023-10/20231024_075559.jpg",
        "/assets/images/puck/diary/2023-10/20231024_082444.jpg",
        "/assets/images/puck/diary/2023-10/20231024_082448.jpg",
        "/assets/images/puck/diary/2023-10/20231024_082449.jpg",
      ],
      [
        "/assets/images/puck/diary/2023-10/mini_20231010_071238.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071339.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071447.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071449.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071453.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071454.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071504.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071506.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071509.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231010_071511.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231024_075553.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231024_075554.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231024_075557.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231024_075559.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231024_082444.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231024_082448.jpg",
        "/assets/images/puck/diary/2023-10/mini_20231024_082449.jpg",
      ],
    ],
    alts: [],
  },
  {
    title: "offer.janowo.diary.timeline.september",
    year: 2023,
    pics: [
      [
        "/assets/images/puck/diary/2023-09/20230909_083315.jpg",
        "/assets/images/puck/diary/2023-09/20230909_083317.jpg",
        "/assets/images/puck/diary/2023-09/20230909_083319.jpg",
        "/assets/images/puck/diary/2023-09/20230909_083320.jpg",
        "/assets/images/puck/diary/2023-09/20230909_083327.jpg",
        "/assets/images/puck/diary/2023-09/20230909_083329.jpg",
        "/assets/images/puck/diary/2023-09/20230909_084547.jpg",
        "/assets/images/puck/diary/2023-09/20230909_084558.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063635.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063636.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063639.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063643.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063711.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063751.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063753.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063755.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063807.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063809.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063811.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063813.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063815.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063826.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063830.jpg",
        "/assets/images/puck/diary/2023-09/20230920_063836.jpg",
        "/assets/images/puck/diary/2023-09/20230920_064753.jpg",
        "/assets/images/puck/diary/2023-09/20230928_073452.jpg",
        "/assets/images/puck/diary/2023-09/20230928_073539.jpg",
        "/assets/images/puck/diary/2023-09/20230928_073636.jpg",
        "/assets/images/puck/diary/2023-09/20230928_073639.jpg",
        "/assets/images/puck/diary/2023-09/20230928_073640.jpg",
        "/assets/images/puck/diary/2023-09/20230928_073647.jpg",
        "/assets/images/puck/diary/2023-09/20230928_073648.jpg",
      ],
      [
        "/assets/images/puck/diary/2023-09/mini_20230909_083315.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230909_083317.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230909_083319.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230909_083320.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230909_083327.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230909_083329.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230909_084547.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230909_084558.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063635.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063636.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063639.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063643.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063711.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063751.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063753.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063755.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063807.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063809.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063811.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063813.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063815.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063826.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063830.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_063836.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230920_064753.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230928_073452.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230928_073539.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230928_073636.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230928_073639.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230928_073640.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230928_073647.jpg",
        "/assets/images/puck/diary/2023-09/mini_20230928_073648.jpg",
      ],
    ],
    alts: [],
  },
]
