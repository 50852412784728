import React, { useState } from "react"
import { DescriptionStyled, PuckIcons } from "../janowotwo/janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"
import { injectIntl } from "gatsby-plugin-intl"
import imagePuck from "../../../images/puck/z16-2-0002-tablet.jpg"
import imagePuckLArge from "../../../images/puck/z16-2-0002.jpg"
import FsLightbox from "fslightbox-react"

import icon1 from "@/images/z-17/icon-1.png"
import icon2 from "@/images/z-17/icon-2.png"
import icon3 from "@/images/z-17/icon-3.png"
import icon4 from "@/images/z-17/icon-4.png"
import icon5 from "@/images/z-17/icon-5.png"
import icon6 from "@/images/z-17/icon-6.png"

const PuckDescription = ({ intl }) => {
  const [lightboxController, setLightboxController] = useState({
    toggler: false,
    slide: 1,
  })

  const icons = [
    {
      icon: icon4,
      title: "Plaża i Zatoka Pucka",
      text: `15 min spacerem do plaży <br/>4 minuty rowerem do Nowej Mariny<br/>
             2 minuty samochodem do Molo`,
    },
    {
      icon: icon5,
      title: "Szlaki rowerowe",
      text: `Popularne szlaki rowerowe prowadzące do:<br/>
            Plaży i Mariny w Pucku (4 minuty)
            <br/>
            Półwyspu Helskiego,
            <br/>
            Rezerwatu Przyrody „Beka” w Osłoninie,
            <br/>
            Plaży w Mechelinkach i Rewie.`,
    },

    {
      icon: icon6,
      title: "Sporty wodne",
      text: `Idealne miejsce dla fanów sportów wodnych i żeglarstwa - możliwość uprawiania żeglarstwa, windsurfingu, kitesurfingu, kajakarstwa, stand-up paddleboardingu (SUP) i nurkowania.<br/>Nowoczesna Marina znajdująca się zaledwie kilkanaście minut spacerem od osiedla.`,
    },

    {
      icon: icon1,
      title: "Doskonała lokalizacja",
      text:
        ` 10 minut jazdy samochodem od Władysławowa,<br/>
          20 minut jazdy samochodem od Gdyni,<br/>
          niewielka odległość od ścisłego centrum Pucka,<br/>
          500 m do najbliższego centrum handlowego`
    },
    {
      icon: icon2,
      title: "Komunikacja",
      text: "Przystanek autobusowy 20 m. od inwestycji – transport bezpośrednio do centrum i na plażę.<br/>Stacja PKP 700 m. od inwestycji – wygodny dojazd do Trójmiasta.",
    },
    {
      icon: icon3,
      title: "Tereny zielone",
      text: "Otaczające inwestycję tereny zielone.<br/>Piękne ogrody i nasadzenia drzewostanu.",
    },


  ]

  return (
    <DescriptionStyled>
      <div className="content description" style={{ padding: 0 }}>
        <Container className="no-padding">
          <Row>
            <Col
              className="no-padding"
              style={{ padding: 0 }}
              xs="12"
              sm="12"
              md="12"
              lg="4"
            >
              <Container style={{ padding: 0, margin: 0 }} fluid>
                <Row>
                  <Col className="title" xs="12">
                    <h2>
                      {intl.formatMessage({
                        id: "offer.janowo.description.title",
                      })}
                    </h2>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>
                      Inwestycja charakteryzuje się 4&#8209;kondygnacyjną
                      zabudową i doskonale wpisuje się w malowniczą strukturę
                      nadmorskiej miejscowości.
                    </p>
                  </Col>
                  <Col xs={12} className={"paragraph"}>
                    <p>
                      Realizacja Osiedla została podzielona na etapy. W&nbsp;pierwszym z nich, którego zakończenie jest
                      zaplanowane na drugi kwartał 2025 roku, powstaną <strong>dwa nowoczesne budynki, połączone
                      przestronną
                      halą garażową.</strong>
                    </p>
                    <p>
                      Kolejne etapy obejmują wybudowanie 8 budynków, a&nbsp;w&nbsp;tym:
                    </p>
                    <ul>
                      <li>prywatnej strefy rekreacyjno‑wypoczynkowej,</li>
                      <li>siłowni oraz fitnesu,</li>
                    </ul>
                    <p>
                      wyłącznie dla mieszkańców osiedla.
                    </p>
                  </Col>
                  {/* <Col xs={12} className={"paragraph"}>
                    <p>
                      {intl.formatMessage({
                        id: "offer.janowotwo.description.content.second",
                      })}
                    </p>
                  </Col>*/}
                </Row>
              </Container>
            </Col>
            <Col
              className="no-padding"
              xs="12"
              sm="12"
              md="12"
              lg="8"
              style={{ padding: 0 }}
            >
              <Container
                style={{ padding: 0 }}
                className="vector-image-container"
                fluid
              >
                <Row>
                  <Col xs="12" className={"raster-image"}>
                    <img
                      src={imagePuck}
                      alt={"Puck"}
                      style={{ maxWidth: "100%", cursor: "pointer" }}
                      onClick={() =>
                        setLightboxController({
                          slide: 1,
                          toggler: !lightboxController.toggler,
                        })
                      }
                    />
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
          <PuckIcons style={{ marginBottom: "100px" }}>
            <Row>
              {icons.map(icon => (
                <Col xs="12" md={6} lg={4}>
                  <img src={icon.icon} alt={icon.title} />
                  <div>
                    <h4>{icon.title}</h4>
                    <p dangerouslySetInnerHTML={{ __html: icon.text }} />
                  </div>
                </Col>
              ))}
            </Row>
          </PuckIcons>
        </Container>
      </div>
      <FsLightbox
        toggler={lightboxController.toggler}
        sources={[imagePuckLArge]}
        slide={lightboxController.slide}
      />
    </DescriptionStyled>
  )
}

export default injectIntl(PuckDescription)
