import React from "react"
import Helmet from "react-helmet"
import Layout from "../../../components/layout"
import Modal from "../../../components/popup2/popup"
import JanowoTwoVisual from "../../../components/estates/janowotwo/janowoTwoVisual"
import SearchPuck from "@/components/search/searchPuck"
import EstateDisclaimer from "@/components/estates/estateDisclaimer"
import PuckHeader from "@/components/estates/puck/puckHeader"
import PuckDescription from "@/components/estates/puck/puckDescription"
import PuckVisual from "@/components/estates/puck/puckVisual"
import LogBuilding from "@/components/estates/logBuilding"
import list from "@/flats/flatList/puckArray"
import PuckEstatePlan from "@/components/estates/puck/puckEstatePlan"
import PuckLocalization from "@/components/estates/puck/puckLocalization"
import { logs } from "@/data/diary/buildingLogsPuck"
import EstateHeader from "@/components/estates/estateHeader"

import slide1 from "@/images/puck/z16-2-main-slide.jpg"
import slide2 from "@/images/puck/z16-2-0001.jpg"
import slide3 from "@/images/puck/z16-PUCK_UJECIE_1.jpg"
import slide4 from "@/images/puck/z16-2-0003.jpg"
import slide5 from "@/images/puck/z16-2-0004.jpg"
import slide7 from "@/images/puck/z16-2-0002.jpg"

function Puck({ location }) {
  const scrollDestination =
    location && location.state && location.state.position
  // const sortedList = list.sort((a, b) => (!!a[13] ? 1 : -1))
  return (
    <>
      <Helmet>
        <title>WILLBUD | Osiedle Pogodne w Pucku</title>
      </Helmet>
      <Layout>
        <EstateHeader
          title={`Osiedle Pogodne w\u00A0Pucku`}
          place={"PUCK, UL. POGODNA"}
          inProgress
        />
        <JanowoTwoVisual
          slides={[slide1, slide7, slide2, slide3, slide4, slide5]}
          alt={`Osiedle Pogodne w Pucku Wizualizacja`}
        />
        <PuckHeader scrollDestination={scrollDestination} />
        {/* <EstateModalForm
          title={"Osiedle Pogodne w Pucku"}
          estate={"Osiedle Pogodne w Pucku"}
        />*/}

        <PuckDescription />
        <PuckVisual />
        {/*<EstateVisualDownload />*/}

        <PuckEstatePlan scrollDestination={scrollDestination} />
        <PuckLocalization />

        <SearchPuck list={list} estate="puck" />

        {/*<Video video="https://www.youtube.com/embed/xsh54VpDxOc" />*/}
        <LogBuilding
          logs={logs}
          anchor={`/pl/oferta/osiedle-pogodne-w-pucku/#diary`}
        />

        <EstateDisclaimer />
        <Modal />
      </Layout>
    </>
  )
}

export default Puck
