import React from "react"
import { EstatePlanContainer } from "../janowotwo/janowoTwoStyle"
import { Col, Container, Row } from "react-bootstrap"
import planImage from "@/images/puck/molo_w_pucku_4.svg"

const PuckEstatePlan = () => (
  <EstatePlanContainer className="plan">
    <Container>
      <Row>
        <Col className="plan-title" xs="12">
          <h2>Plan Osiedla</h2>
        </Col>
      </Row>
    </Container>
    <Container>
      <Row>
        <Col className="no-padding" xs="12">
          <div className="plan-container" style={{ paddingBottom: "40px" }}>
            <img src={planImage} alt={`Plan`} />
          </div>
        </Col>
      </Row>
    </Container>
  </EstatePlanContainer>
)

export default PuckEstatePlan
